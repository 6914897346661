import request from '@/utils/request'
import CONSTANT from '../../config/global.js'


export function getDynamicList(params) {
  return request({
    url: CONSTANT.TwoHttp + '/external/companyDynamic/dynamicGetList',
    method: 'get',
	params
  })
}
/**
 * 查询官网公司动态数据
 * object{分页对象，相关属性current，pageSize，listMode}
 */
export function getPcList(data){
	return request({
	  url: CONSTANT.TwoHttp + '/external/companyDynamic/List',
	  method: 'post',
	  data

	})
}


