<template>
	<div class="all" id="topall" >

	<div class="bodya" >
	<div class="hydttop">
	  <div class="hydttitle">
		<img class="im" :src="require('../assets/arrow-left.png')" />
		<span class="spana">行业动态</span>
		 <img class="im" :src="require('../assets/arrow-right.png')" />
	   </div>
	   <div class="hydt">
		  <div @click="getSp(item)" :class="['hydtcli',{'hydtclihover':hoverindex==index}]"  v-for="(item,index) in hydt" :key="index" @mouseover="mousemove(index)" @mouseout="mouseout">
			 <img  class="imgac" style="border: 1px solid  #e5e5e5;" :src=" 'http://101.35.0.207:8888/'+item.previewImage" >
			  <div class="one" style="word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{item.title}}</div>
			  <div class="two" style="word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;" >{{item.digest}}</div>
			  <div  class="three">
			   <span class="spandi" >{{item.createdTime}} </span>

			  <div class="tu">
			  <div :class="jiantou&&hoverind==index ? 'imgbhover':'imgb' "></div>
			  <img class="imgc" :src="require('../assets/jiantou.png')" />
			  </div>

			  </div>
		  </div>
	   </div>
	   <el-button @click="getnew(hydtlistQuery)"> 查看更多</el-button>
	</div>
	<div class="jyxw">
		<div class="jyxwtitle" >
			<img class="im" :src="require('../assets/arrow-left.png')"  />
			<span class="spana">公司动态 </span>
			<img class="im" :src="require('../assets/arrow-right.png')" />
		</div>
		<div class="bj">
		<div class="jyxwleft">
			 <div class="time"></div>
			 <img :src="'http://101.35.0.207:8888/'+jyxwsy.previewImage" />
			 <div >{{jyxwsy.title}}</div>
			 <div style="word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{jyxwsy.digest}}</div>
			 <div  @click="getSp(jyxwsy)">了解详情&nbsp;&nbsp;></div>
			  <div class="sj">
				<div>{{this.jyxwtime[0][2]}}</div>
				<div>{{this.jyxwtime[0][1]}}月</div>
			  </div>
		</div>
		<div class="zhong"></div>
		<div class="jyxwright">
			<div class="shell" v-for="(item,index) in jyxw" :key="index">
			    <div class="tandec">
				<div @click="getSp(item)">
				{{item.title}}
				</div>
				<div style="word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
				{{item.digest}}
				</div>
				</div>
				<div class="sjs">
				<div>{{jyxwtime[index+1][2]}}</div>
				<div>{{jyxwtime[index+1][1]}}月</div>
				</div>
			</div>
		</div>

		</div>

		<el-button  @click="getnew(jyxwlistQuery)">查看更多 </el-button>
	</div>
	<div class="jkcs" >
		<div class="title">
			<img class="im" :src="require('../assets/arrow-left.png')"  />
			<span class="spana">健康常识</span>
			<img class="im" :src="require('../assets/arrow-right.png')" />
		</div>
		<div  class="content">
		 <div @click="getSp(item)"   v-for="(item,index) in jkcs" :key="index" :class="['center',{'hydtclihover':contindex ==index}]" @mouseover="move(index)" @mouseout="out"  >
			<img style="border: 1px solid  #e5e5e5;" :src="'http://101.35.0.207:8888/'+item.previewImage" />
			<span>{{item.title}}</span>
			<span style="word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{item.digest}}</span>
			<div>了解详情&nbsp;&nbsp;></div>
		 </div>

		</div>
		<el-button  @click="getnew(jkcslistQuery)">查看更多</el-button>
	</div>
	</div>

	</div>
</template>

<script>

	import {getDynamicList} from "@/api/companyDynamic/companyDynamic.js";
	import {formatDate} from "../components/Timedata.js";
	export default {
		data(){
			return{
				hydtlistQuery:{
				 	current:1,
				 	pageSize:6,
				 	listMode:true,
				 	keyWord:'1',
				 },
				 jyxwlistQuery:{
				  	current:1,
				  	pageSize:6,
				  	listMode:true,
				  	keyWord:'2',
				  },
				  jkcslistQuery:{
				   	current:1,
				   	pageSize:6,
				   	listMode:true,
				   	keyWord:'3',
				   },
				contindex:-1,
				hoverind:-1,
				jiantou:false,
				hoverindex:-1,
				hydt:[
				],
				jyxwsy:{

				},
				jyxw:[
				],
				jkcs:[
				],
				jyxwtime:[

				],
				userfrom:null,
			}
		},
		methods:{
			getSp(dynamicContent){
				this.scroll=document.documentElement.scrollTop || document.body.scrollTop
				window.sessionStorage.setItem("scroll",this.scroll)
				let source = { routerName: 'Dynamic',rotertitle:'九域新闻'};
				Object.assign(dynamicContent, source);
				console.log(dynamicContent)

        this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:5,moduleLevel2:dynamicContent.section,moduleLevel3:dynamicContent.id})

        this.$router.push({name:'DynamicSp',query:{conten: JSON.stringify(dynamicContent) }})


			},
			mousemove(index){
				this.hoverindex=index
				this.jiantou=true
				this.hoverind=index
			},
			mouseout(){
				this.hoverindex=-1
				this.jianto=false
				this.hoverind=-1
			},
			move(index){
				this.contindex=index
			},
			out(){
				this.contindex=-1
			},
			getnew(name){
				this.$router.push({name:'DynamicDetails',query:{title: JSON.stringify(name)}})
			},
			getdata(){
				var a = {subsystemIdentificationCode:'1E1FF846F78548E6AC29076215F10CDA'}
				getDynamicList(a).then((res)=>{
					for(let a=0;a<res.data.length;a++){
						for(let b=0;b<res.data[a].length;b++){
							res.data[a][b].createdTime=formatDate(res.data[a][b].createdTime)
						}
					}
					this.hydt=res.data[0]
					this.jyxwsy=res.data[1][0]
				    res.data[1].shift()
					this.jyxw=res.data[1]
					this.jkcs=res.data[2]
					console.log('///',this.jyxwtime)
					this.jyxwtime.push(this.jyxwsy.createdTime.split('/'))
					for(let a=0;a<this.jyxw.length;a++){
						this.jyxwtime.push(this.jyxw[a].createdTime.split('/'))
					}
					this.gujia=false
				})

			}

		},
		 created() {
		 	 this.getdata()

		 },
		 mounted() {

       this.userfrom = window.sessionStorage.getItem("from")
       this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:5,moduleLevel2: 1})

		 	      setTimeout(()=>{
				if(this.$route.query.title){
					let time=window.sessionStorage.getItem("scroll")
					window.scrollTo(0, time)
				}else{
				 window.scrollTo(0,0);
				}
				  },400)

     }

	}
</script>

<style type="text/css" scoped>

	.tu{
		width: 50px;
		height: 50px;
		position: absolute;
		margin-left: 72%;
	}
	.sjs{
		position: absolute;
		top: 30px;
		left: -34px;
	}
	.sjs>div:nth-child(1){
		width: 70px;
		height: 40px;
		background: #3EA5FF;
		color: #FFFFFF;
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 40px;
	}
	.sjs>div:nth-child(2){
		width: 70px;
		height: 30px;
		background: #025D91;
		 color: #FFFFFF;
		 font-size: 14px;
		 font-family: PingFangSC-Semibold, PingFang SC;
		 font-weight: 600;
		 color: #FFFFFF;
		 line-height: 30px;
	}
	.sj{
		position: absolute;
	}
	 .sj>div:nth-child(1){
		 width: 70px;
		 height: 40px;
		 background: #3EA5FF;
		 color: #FFFFFF;
		 font-size: 24px;
		 font-family: PingFangSC-Semibold, PingFang SC;
		 font-weight: 600;
		 color: #FFFFFF;
		 line-height: 40px;

	 }
	 .sj>div:nth-child(2){
		 width: 70px;
		 height: 30px;
		 background: #025D91;
		  color: #FFFFFF;
		  font-size: 14px;
		  font-family: PingFangSC-Semibold, PingFang SC;
		  font-weight: 600;
		  color: #FFFFFF;
		  line-height: 30px;

	 }
	 .imgc{
		 width: 50px;
		 height: 50px;
		 position: absolute;
		right: 9%;
		 background: #3EA5FF;
		 top:0px;
		 opacity: 0;
		 z-index: -1;
		cursor:pointer;
	 }
	.hydtclihover{
	box-shadow: 0px 4px 40px 0px rgba(222, 232, 238, 1);
	transform: translateY(-9px);
	 transition: all 0.2s linear  ;
	  cursor:pointer;
	}

	.title{
		margin-bottom: 80px;
	}
	.content>div>div:nth-child(4){
			width: 340px;
		text-align: left;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #025D91;
		line-height: 25px;
		margin-left: 10px;
		margin-bottom: 10px;

	}
	.content>div>img:nth-child(1){
		width: 340px;
		height: 170px;
		margin: 20px;
	}
	.content>div>span:nth-child(2){
		display: block;
	width: 340px;
		height: 33px;
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.85);
		line-height: 33px;
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		 text-overflow: ellipsis
	}

	.content{
		display: flex;
		justify-content: space-around;
	}


	.center{
		width: 380px;
		height: 420px;
		background: #FFFFFF;
		border: 1px solid #d9e3e8;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.content>div>span:nth-child(3){
		width: 340px;
		height: 60px;
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #6D7278;
		line-height: 30px;
		text-align: left;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.zhong{
		width: 60px;
	}
	.jkcs{
		margin-top: 80px;
		margin-bottom: 16rem;
	}
	.jkcs>div:nth-child(2){
		margin-bottom: 70px;
	}
	.jyxwleft{
		background-color:#FFFFFF;
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.shell{
		width: 630px;
		height: 130px;
		background: #F8F8F8;
		margin-bottom: 25px;
		position: relative;
	}
	.shell:hover .tandec>div:nth-child(1) {
		color: #019FD8;
		 cursor:pointer;
	}
	.jyxwright{
		margin-bottom: 20px;

	}
	.tandec{
		width: 500px;

		margin-left: 70px;
	}
	.tandec>div:nth-child(1){
		width: 491px;
		text-align: left;
		font-size: 21px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.85);
		line-height: 55px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	.tandec>div:nth-child(2){
		width: 541px;
		text-align: left;
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #6D7278;
		line-height: 26px;

	}
	.jyxwtitle{
		 padding-bottom: 70px;
	}
	.jkcstitle{
		padding-bottom:70px ;
	}
	.bj{
		display: flex;
		justify-content: center;
	}
	.jyxwleft>img:nth-child(2){
		width: 500px;
		height: 240px;
	}
	.jyxwleft>div:nth-child(4){
		width:490px;
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #6D7278;
		text-align: left;
		margin-top: 20px;
		margin-left: 10px;
		margin-bottom: 20px;
		line-height: 30px;
	}
	.jyxwleft>div:nth-child(5){
	  text-align: left;
	 width: 163px;
	 font-size: 18px;
	 font-family: PingFangSC-Semibold, PingFang SC;
	 font-weight: 600;
	 color: #025D91;
	 line-height: 25px;
	 margin-left: 10px;
	  margin-bottom: 10px;
	   cursor:pointer;
	}
	.jyxwleft>div:nth-child(3){

		width:490px;
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.85);
		text-align: left;
		margin-top: 22px;
		margin-left: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	.all{

		width:100%;
		display: flex;
		 flex-direction: column;
		 background-color: #FFFFFF;
		 align-items: center;
		padding-top: 80px;
	}
	.bodya{
	  width: 70%;
	  box-sizing: border-box;
	  margin: 0 auto;

	}
	.hydt{
		 flex-wrap: wrap;
		display: flex;
		justify-content: center;
	}
	.spandi{
		margin-left: -208px;
	}

	.hydttop{
	  width: 100%;

	}
	.hydtcli{
		margin-bottom: 2vw;
		margin-left: 0.7vw;
		margin-right: 0.7vw;
	    width: 380px;
		background-color: RGBA(248, 248, 248, 1);
		 cursor:pointer;
	}
	.hydtcli:hover .tu>.imgc{
		opacity: 1;
		z-index: 1;
		 animation: clip 0.5s linear;
	}
	 .imgac{
		width: 100%;
		height: 200px;

	}
	.three{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		position: relative;
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 500;
		color: #C9C9C9;
		line-height: 33px;
	}
	.imgb{
		position: absolute;
		right: 9%;
		width: 50px;
		height: 50px;
		background-image: url(../assets/huijiantou.png);
		background-size: cover;


	}
	.imgbhover{
		position: absolute;
		right: 9%;
		width: 50px;
		height: 50px;
	  background-color:#3EA5FF;
	}


	/* .tu:hover .imgc{
		opacity: 1;
		z-index: 1;
		 animation: clip 0.5s linear;
	} */
	 @keyframes clip {
	            0% {
	                clip-path: polygon(0 0, 35% 0, 0 68%, 0 67%);
	            }
	            10% {
	                clip-path: polygon(0 0, 35% 0, 0 99%, 0% 100%);
	            }
	            30% {
	                clip-path: polygon(0 0, 75% 0, 34% 100%, 0 100%);
	            }
	            50% {
	                clip-path: polygon(0 0, 100% 0, 51% 100%, 0 100%);
	            }
	            70% {
	                clip-path: polygon(0 0, 100% 0, 74% 100%, 0 100%);
	            }
	            100% {
	                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	            }
	        }

	.one{
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.85);
		line-height: 33px;
		text-align: left;
		width: 317px;
		height: 66px;
		margin: 0 auto;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	 .two{
		width: 317px;

		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #6D7278;
		line-height: 24px;
		margin: 0 auto;
		text-align: left;
		margin-bottom: 20px;
	}
	.jyxw{
		width: 100%;
		margin-top: 80px;
	}
	.spana{
		font-size: 2.6rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: rgba(0,0,0,0.85);
		line-height: 3.7rem;

		margin-bottom: 3rem;
		margin-left: 8px;
		margin-right: 8px;
	}
	.hydttitle{
		  padding-bottom: 70px;
	}
	.imga{
		display: block;
      height: 600px;
	  width: 100%;
	}
	.im{
		width: 10.9rem;
		height: 1.1rem;
		margin-bottom: 3px;
	}
	.el-button{
		width: 150px;
		height: 50px;
	}


</style>
<style>
	  .el-button:focus,.el-button:hover{
		  color:#019FD8;
		border-color: #019FD8;
		background-color: #ecf5ff;
	  }
</style>
